exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-financial-tsx": () => import("./../../../src/templates/financial.tsx" /* webpackChunkName: "component---src-templates-financial-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-stock-tsx": () => import("./../../../src/templates/stock.tsx" /* webpackChunkName: "component---src-templates-stock-tsx" */),
  "component---src-templates-update-tsx": () => import("./../../../src/templates/update.tsx" /* webpackChunkName: "component---src-templates-update-tsx" */),
  "component---src-templates-veiculo-tsx": () => import("./../../../src/templates/veiculo.tsx" /* webpackChunkName: "component---src-templates-veiculo-tsx" */)
}

